import { queryDefaults } from '@/utils/query.config'
import AdoriService from '@/services/adori'
import AdoriServiceV6 from '@/services/adori_v6'
import store from '@/store'
import { useQuery, useMutation, useQueryClient } from 'vue-query'
import * as Sentry from '@sentry/vue'
import { ref } from '@vue/composition-api'

// Query Keys
export enum youtubeQueryKeys {
  YOUTUBE_SUMMARY = 'YOUTUBE_SUMMARY',
  YOUTUBE_ACCOUNT = 'YOUTUBE_ACCOUNT',
  YOUTUBE_CATEGORIES = 'YOUTUBE_CATEGORIES',
  YOUTUBE_PLAYLIST = 'YOUTUBE_PLAYLIST',
}

// Queries
const getYoutubeSummary = (params: { networkId: string; showId?: string }) => {
  return AdoriService.getIntegrationsSummary(params.networkId, params.showId)
}
const getYoutubeAccount = (params: { networkId: string; showId?: string }) => {
  return AdoriService.youtube_linked_account(params.networkId, params.showId)
}
const getYoutubeCategories = (params: { networkId: string; showId?: string }) => {
  return AdoriService.youtube_categories(params.networkId, params.showId)
}
const getYoutubePlaylist = (params: { networkId: string; showId?: string }) => {
  return AdoriService.youtube_playlist(params.networkId, params.showId)
}
const youtubeSignOut = (params: { networkId: string; showId?: string }) => {
  return AdoriService.youtube_revoke(params.networkId, params.showId)
}
const getPublicFeed = (params: { url: string }) => {
  return AdoriServiceV6.youtubeRssFeedEpisodes(params)
}
const createYoutubeFeed = (networkId: string, data: any) => {
  return AdoriService.youtube_create_feed(networkId, data)
}

// Hooks
export const useGetYoutubeSummary = (params: { showId?: string }, interval: any = false) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  const hasError = ref(false)
  return useQuery(
    [youtubeQueryKeys.YOUTUBE_SUMMARY, networkId, params],
    () => getYoutubeSummary({ networkId, ...params }),
    {
      onSuccess: (data: any) => {
        store.commit('setSigninStatus', { id: params.showId ? params.showId : networkId, data })
      },
      onError: () => {
        hasError.value = true // Set error state on failure
      },
      refetchInterval: interval,
      enabled: !!localStorage.getItem('networkId'),
      refetchOnWindowFocus: !hasError.value,
      ...queryDefaults,
    }
  )
}
export const useGetYoutubeAccount = (params: { showId?: string }, { enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery(
    [youtubeQueryKeys.YOUTUBE_ACCOUNT, networkId, params],
    () => getYoutubeAccount({ networkId, ...params }),
    {
      select: (data: any) => {
        return {
          gProfile: data[0].gProfile,
        }
      },
      onSuccess: (data: any) => {
        store.commit('setYoutubeProfile', data.gProfile)
      },
      enabled,
      ...queryDefaults,
    }
  )
}
export const useGetYoutubeCategories = (params: { showId?: string }, { enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  const hasError = ref(false)
  return useQuery(
    [youtubeQueryKeys.YOUTUBE_CATEGORIES, networkId, params],
    () => getYoutubeCategories({ networkId, ...params }),
    {
      onSuccess: (data: any) => {
        store.commit('setCategories', data)
      },
      onError: () => {
        hasError.value = true // Set error state on failure
      },
      enabled,
      refetchOnWindowFocus: !hasError.value,
      ...queryDefaults,
    }
  )
}
export const useGetYoutubePlaylist = (params: { showId?: string }, { enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  const hasError = ref(false)
  return useQuery(
    [youtubeQueryKeys.YOUTUBE_PLAYLIST, networkId, params],
    () => getYoutubePlaylist({ networkId, ...params }),
    {
      onSuccess: (data: any) => {
        store.commit('setPlaylist', { id: params?.showId ? params.showId : networkId, data: data.data })
      },
      onError: () => {
        hasError.value = true // Set error state on failure
      },
      enabled,
      refetchOnWindowFocus: !hasError.value,
      ...queryDefaults,
    }
  )
}

// Mutations
export const useGetPublicFeed = () => {
  return useMutation((params: any) => getPublicFeed(params), {
    onSuccess: (data: any) => {
      store.commit('setCurrentPodcast', data)
      store.commit('setPublicFeedEpisodes', data.episodes)
    },
    onError: (error: any) => {
      Sentry.captureException(error)
      store.dispatch('pushNotification', {
        text: 'This feed is not available',
        type: 'ERROR',
      })
    },
  })
}
export const useCreateYoutubeFeed = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation((data: any) => createYoutubeFeed(networkId, data), {
    onSuccess(data) {
      store.commit('setCreatedYoutubeFeed', data)
    },
  })
}

export const useYoutubeSignOut = (params: { showId?: string }) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  const queryClient = useQueryClient()
  return useMutation(() => youtubeSignOut({ networkId, ...params }), {
    onSuccess: () => {
      store.commit('setSigninStatus', {
        id: params.showId ? params.showId : networkId,
        data: {
          ...store.getters.signinStatus[params.showId ? params.showId : networkId],
          youtube: false,
        },
      })
      queryClient.invalidateQueries([youtubeQueryKeys.YOUTUBE_SUMMARY, networkId, params])
    },
  })
}
