


















































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class AudiogramVideosList extends Vue {
  @Prop({ default: 1 })
  selectedRangeStart!: number
  @Prop({ default: 10 })
  selectedRangeEnd!: number
  @Prop({ default: 0 })
  totalVideos!: number
  @Prop({ default: [] })
  videoSources!: object[]
  @Prop() selectedTab!: string
  @Prop() cols!: number

  hoverId = ''
  //chunking of images in masonry grid
  get masonryColumns() {
    console.log(this.videoSources)

    let columns = []
    let cols = this.cols || 2 //change it for n-columns
    let mid = Math.ceil(this.videoSources.length / cols)
    for (let col = 0; col < cols; col++) {
      columns.push(this.videoSources.slice(col * mid, col * mid + mid))
    }
    return columns
  }

  handleOver(e: any) {
    e.target.play()
  }
  handleOut(e: any) {
    e.target.pause()
  }

  handleMouseHover(item: any) {
    this.hoverId = item.id
  }

  handleNavigation(nav: string) {
    this.$emit('handleNavigation', nav)
  }

  loadAsset(video: any) {
    this.$emit('load-video', video)
    // this.$store.commit('setTagVideoId', video.id)
  }
}
