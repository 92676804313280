export enum sortOrder {
  'Recent First' = 'recent_first',
  'Oldest First' = 'oldest_first',
}

export enum uploadStatus {
  QUEUED = 'QUEUED',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
  ABORTED = 'ABORTED',
  SCHEDULED = 'SCHEDULED',
  PROCESSING = 'PROCESSING',
  UPLOADING = 'UPLOADING',
  PUBLISHED = 'PUBLISHED',
}

export enum orientationStatus {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
  SQUARE = 'SQUARE',
}

export const privacyList = [
  { title: 'Public', value: 'public' },
  { title: 'Unlisted', value: 'unlisted' },
  { title: 'Private', value: 'private' },
]

export const categoryList = [
  { id: 1, title: 'Film & Animation' },
  { id: 2, title: 'Autos & Vehicles' },
  { id: 10, title: 'Music' },
  { id: 15, title: 'Pets & Animals' },
  { id: 17, title: 'Sports' },
  { id: 18, title: 'Short Movies' },
  { id: 19, title: 'Travel & Events' },
  { id: 20, title: 'Gaming' },
  { id: 22, title: 'People & Blogs' },
  { id: 23, title: 'Comedy' },
  { id: 24, title: 'Entertainment' },
  { id: 25, title: 'News & Politics' },
  { id: 26, title: 'Howto & Style' },
  { id: 27, title: 'Education' },
  { id: 28, title: 'Science & Technology' },
  { id: 29, title: 'Nonprofits & Activism' },
]
