import { contains } from '@/utils/misc'
import moment from 'moment'

export function formatTimeAgo(dateString: any) {
  return moment(dateString).fromNow()
}

export function toReadableDate(time: number) {
  const date = new Date(time).toString()
  return date.slice(date.indexOf(' ')).slice(0, date.indexOf(':') - 5)
}

export function correctPosition(
  index: number,
  tags: number[],
  duration: number,
  timeLineZoom: number,
  networkType: string
) {
  const tagPositions = tags.map((e: any) => e.offsetMillis)

  const position = [
    'YOUTUBE_METERED',
    'YOUTUBE_ENTERPRISE',
    'YOUTUBE',
    'YOUTUBE_PRO',
    'VIDEO_FREE',
    'VIDEO_BASIC',
    'VIDEO_PRO',
  ].includes(networkType)
    ? Math.round(tagPositions[index] / (timeLineZoom * 1000)) * timeLineZoom * 1000
    : Math.max((Math.round(tagPositions[index] / 3000) * 3 + 1) * 1000, 4000)

  const otherPositions = tagPositions.filter((e, i) => i !== index)
  let positionLeft = position
  let positionRight = position

  while (contains(otherPositions, positionLeft) && contains(otherPositions, positionRight)) {
    if (positionLeft >= 0) {
      positionLeft -= timeLineZoom * 1000
      if (positionLeft <= 0) {
        positionLeft = 0
      }
    }
    if (positionRight <= duration) {
      positionRight += timeLineZoom * 1000
      if (positionRight >= duration) {
        positionRight = duration
      }
    }
    if (positionLeft < 1000 && positionRight > duration) {
      return null
    }
  }

  if (!contains(otherPositions, positionLeft)) {
    return positionLeft
  }

  if (!contains(otherPositions, positionRight)) {
    return positionRight
  }

  return null
}
export function correctAudiogramPosition(index: number, audiograms: number[], duration: number) {
  // Todo pick up later when multi audiogram support added
  const audiogramPositions = audiograms.map((e: any) => e.offsetMillis)
  const otherPositions = audiogramPositions.filter((e, i) => i !== index)
  const position = 0
  if (otherPositions.length > 0) return null
  return position
}

export function secondsToMmss(seconds: number) {
  const ms = [seconds / 60, seconds % 60].map((e) => toClockLike(Math.floor(e)))
  return ms.join(':')
}

export function readableMillisToSS(ms: number) {
  if (ms) {
    const seconds = Math.ceil(ms / 1000)
    const hrs = Math.floor(seconds / 3600)
    const min = Math.floor((seconds % 3600) / 60)
    const sec = Math.floor(seconds % 60)
    return (hrs ? hrs + 'h ' : '') + (min ? min + 'm ' : '') + (sec ? sec + 's' : '')
  }
  return 'Calculating...'
}

export function msToSeconds(s: number) {
  // Pad to 2 or 3 digits, default is 2
  function pad(n: number, z?: number) {
    z = z || 2
    return ('00' + n).slice(-z)
  }
  const ms = s % 1000
  s = (s - ms) / 1000
  const secs = s % 60
  s = (s - secs) / 60
  const mins = s % 60
  const hrs = (s - mins) / 60
  return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3)
}

export function removeFormatting(value: string) {
  try {
    const splitArr = value.split(':')
    if (splitArr.length === 3) {
      const hrs: number = Number(splitArr[0])
      const mins: number = Number(splitArr[1])
      const seconds: string = splitArr[2]
      const splitSeconds: Array<string> = seconds.split('.')
      const ms: number = hrs * 60 * 60000 + mins * 60000 + Number(splitSeconds[0]) * 1000
      return ms
    } else if (splitArr.length == 2) {
      const mins: number = Number(splitArr[0])
      const seconds: number = Number(splitArr[1])
      const ms = mins * 60000 + seconds * 1000
      return ms
    } else if (splitArr.length == 1) {
      const seconds: number = Number(splitArr[0])
      const ms = seconds * 1000
      return ms
    } else {
      return 0
    }
  } catch (error) {
    return 0
  }
}

export function returnHHMMSS(value: string) {
  const splitArr = value.split(':')
  if (splitArr.length === 3) {
    const hrs: number = Number(splitArr[0])
    const mins: number = Number(splitArr[1])
    const seconds: number = Number(splitArr[2])
    return {
      hours: hrs,
      minutes: mins,
      seconds: seconds,
    }
  } else if (splitArr.length === 2) {
    const mins: number = Number(splitArr[1])
    const seconds: number = Number(splitArr[2])
    return {
      minutes: mins,
      seconds: seconds,
    }
  } else {
    return 0
  }
}

export function removeMsFormatting(value: string) {
  try {
    const splitArr = value.split(':')
    if (splitArr.length === 2) {
      const mins: number = Number(splitArr[0])
      const seconds: string = splitArr[1]
      const splitSeconds: Array<string> = seconds.split('.')
      const s: number = mins * 60000 + Number(splitSeconds[0]) * 1000 + Number(splitSeconds[1])
      return s
    } else if (splitArr.length === 3) {
      const hrs: number = Number(splitArr[0])
      const mins: number = Number(splitArr[1])
      const seconds: string = splitArr[2]
      const splitSeconds: Array<string> = seconds.split('.')
      const ms: number = hrs * 60 * 60000 + mins * 60000 + Number(splitSeconds[0]) * 1000 + Number(splitSeconds[1])
      return ms
    } else {
      return 0
    }
  } catch (error) {
    return 0
  }
}

export function toClockLike(e: number) {
  return e === 0 ? '00' : e < 10 ? '0' + e : e.toString()
}

export function toHms(millis: number) {
  const seconds = millis / 1000
  const suffixes = ['h', 'm', 's']
  return [Math.floor(seconds / 3600), Math.floor((seconds % 3600) / 60), Math.ceil(seconds % 60)]
    .map((e, i) => (e ? e + suffixes[i] : ''))
    .join(' ')
}

export function returnFormatHHMMS(millis: number, returnMs = false) {
  let seconds = millis / 1000
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds - hours * 3600) / 60) % 60
  seconds = Math.floor(seconds % 60)
  const milliSeconds = Math.round(((seconds % 60) - Math.floor(seconds % 60)) * 1000)
  return returnMs
    ? `${toClockLike(hours)}:${toClockLike(minutes)}:${toClockLike(seconds)}:${toClockLike(milliSeconds)}`
    : `${toClockLike(hours)}:${toClockLike(minutes)}:${toClockLike(seconds)}`
}

export function getTimelinePosition(clientX: number, gapSecond: number) {
  const at: any = document.getElementById('audioTaggerEl')
  let rect = at.getBoundingClientRect()
  let brX = rect.x
  const offset = ((at.scrollLeft + clientX - brX - 125) / 120) * gapSecond
  let posInSecond = Math.round(offset)
  return posInSecond * 1000 + (gapSecond > 1 ? gapSecond - 1 : gapSecond) * 1000
}

export function daysRemaining(startDate: string, endDate: string) {
  const daysRemaining = moment(endDate).diff(moment(startDate), 'days')
  const daysRemainingText = moment(startDate).to(moment(endDate))
  return { daysRemaining, daysRemainingText }
}
