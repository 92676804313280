import {
  useAddBillingCard,
  useGetBillingCard,
  useGetBillingInvoices,
  useGetBillingSummary,
  useUpdateBillingCard,
} from '@/hooks/billing'
import { computed, SetupContext } from '@vue/composition-api'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Getter } from 'vuex-class'
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import moment from 'moment'

@Component({
  setup(_, { root }: SetupContext) {
    const isYoutubeNew = computed(
      () => root.$store.getters.isYoutubeNew && root.$permissions.isManageNetworkBillingAllowed()
    )
    const { data: summaryData, isLoading: isSummaryLoading } = useGetBillingSummary({
      enabled: isYoutubeNew,
    })
    const { data: invoiceData, isLoading: isInvoicesLoading } = useGetBillingInvoices({
      enabled: isYoutubeNew,
    })
    const { data: cardData, isLoading: isCardLoading } = useGetBillingCard({
      enabled: isYoutubeNew,
    })

    const { mutate: addCard } = useAddBillingCard()
    const { mutate: updateCard } = useUpdateBillingCard()

    return {
      summaryData,
      isSummaryLoading,
      invoiceData,
      isInvoicesLoading,
      cardData,
      isCardLoading,
      addCard,
      updateCard,
    }
  },
})
export default class Billing extends Vue {
  @Getter networkType!: any
  @Getter isYoutubeNew!: any

  summaryData!: any
  isSummaryLoading!: boolean
  invoiceData!: any
  isInvoicesLoading!: boolean
  cardData!: any
  isCardLoading!: boolean

  addCard!: any
  updateCard!: any

  get billingStart() {
    return moment(this.summaryData.current_period_start).format('ll')
  }
  get billingEnd() {
    return moment(this.summaryData.current_period_end).format('ll')
  }
  get paymentEnd() {
    return moment(this.summaryData.current_period_end).format('ll')
  }

  get billingInvoiceItems() {
    if (this.networkType === 'YOUTUBE_PRO') {
      const items = get(this.invoiceData, 'items', [])
      const index = items.findIndex((prop: any) => prop.category === 'transcription')
      if (index === 0) {
        get(this.invoiceData, 'items', []).splice(index, 1)
      }
      return get(this.invoiceData, 'items', [])
    }
    if (this.networkType === 'YOUTUBE_METERED') {
      return get(this.invoiceData, 'items', [])
    }

    if (this.networkType === 'YOUTUBE_ENTERPRISE') {
      return []
    }
  }

  get total_balance() {
    const total_balance_amount =
      get(this.invoiceData, 'unconditional_balance', 0) +
      get(this.invoiceData, 'video_conversion_balance', 0) +
      get(this.invoiceData, 'transcription_balance', 0)
    return total_balance_amount
  }

  get lifetime_credits_given() {
    if (get(this.invoiceData, 'lifetime_credits_given', 0) > 0) {
      return (this.invoiceData.lifetime_credits_given / 100).toFixed(2)
    }
    return 0
  }

  get remaining_balance() {
    const remaining = this.total_balance - (this.invoiceData.total - this.invoiceData.due)
    return remaining
  }

  get creditBalanceAvailable() {
    if (this.total_balance > 0) {
      return (this.total_balance / 100).toFixed(2)
    }
    return 0
  }

  get isUsageAllowed() {
    return ['YOUTUBE_METERED', 'YOUTUBE_PRO'].includes(this.networkType)
  }

  get cardAdded() {
    if (!isEmpty(this.cardData)) {
      return true
    }
    return false
  }

  get showPopup() {
    return this.isUsageAllowed && !this.cardAdded && this.creditBalanceAvailable === 0
  }
}
