import Vue from 'vue'
import get from 'lodash.get'
import moment from 'moment'
import isEmpty from 'lodash.isempty'
import store from '@/store'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import { getTagUploadBody, getTagModalData, getEmptyTagModalData } from '@/utils/tag'
import AdoriService from '@/services/adori'
import AdoriServicev6 from '@/services/adori_v6'
import { TagImages } from '@/imageAssets'

const state: any = {
  tagPreview: {
    img: null,
    saveable: false,
    shareable: false,
    orientation: null,
    tagType: null,
  },
  current: null,
  audioAdEdit: {},

  audioPreview: {
    episodeUid: null,
  },

  showTagModal: {
    tagId: null,
  },

  iframeEmbed: {
    episodeUid: null,
  },

  confirm: {
    title: '',
    description: '',
    yesButtonText: 'yes',
    noButtonText: 'no',
    inputValue: 'Untitled',
    inputPlaceholder: 'Enter text here',
    options: [],
    error: '',

    onConfirm: function () {},

    onCancel: function () {},
  },

  tagEdit: getEmptyTagModalData(),
  audiogram: {},
  audiogramOpenFrom: '',
  newTagOpenFrom: '',
  windowOrientation: '',
  tagEditOnSave: () => {},

  audioTrackEditor: {
    uid: '',
    name: '',
    description: '',
    imageId: '',
    summary: '',
    explicitContent: false,
    language: '',
    keywords: [],
  },

  audioTrackAdsEditor: {
    uid: '',
    name: '',
    durationMillis: 0,
  },

  audioTrackPlayer: {
    uid: '',
    source: '',
  },

  profileEditor: {
    id: '',
    name: '',
    networkName: '',
    bio: '',
    profileImageId: '',
    email: '',
    errorName: '',
    errorBio: '',
  },

  transcript: {
    transcriptsByAudioUid: {},
    audioUid: null,
  },

  addMember: {
    podcastUid: null,
  },

  addShowMember: {
    podcastUid: null,
  },

  setMembers: {
    podcastUid: null,
  },

  podcastEditor: {
    podcastUid: null,
  },

  podcastEpisodeEditor: {
    episodeUid: null,
  },

  exportInsights: {
    level: null,
    id: null,
    name: '',
  },

  replaceAudio: {
    episodeUid: null,
  },

  paymentEstimates: {
    planId: '',
  },

  daastValues: [],
  campaignId: '',
  iabContentCategories: [],

  placeHolderImages: [
    TagImages.photo,
    TagImages.poll,
    TagImages['Web link'],
    TagImages.call,
    TagImages.location,
    TagImages.message,
    TagImages.audio,
    TagImages.buy,
    TagImages.artag,
  ],
  timelinePosition: null,
  isDuplicate: false,
  creditCardAlert: false,
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  tagEditImageBlob: (state) => state.tagEdit.data.imageBlob,
  tagEditImage: (state) => state.tagEdit.data.image,
  paymentEstimates: (state) => state.paymentEstimates,
  audioAdEdit: (state) => state.audioAdEdit,
  daastValues: (state) => state.daastValues,
  campaignId: (state) => state.campaignId,
  iabContentCategories: (state) => state.iabContentCategories,
  current: (state) => state.current,
  placeHolderImages: (state) => state.placeHolderImages,
  timelinePosition: (state) => state.timelinePosition,
  audiogramOpenFrom: (state) => state.audiogramOpenFrom,
  newTagOpenFrom: (state) => state.newTagOpenFrom,
  windowOrientation: (state) => state.windowOrientation,
  isDuplicate: (state) => state.isDuplicate,
  creditCardAlert: (state) => state.creditCardAlert,
}

const mutations: MutationTree<any> = {
  resetState(state) {
    Object.keys(state).forEach((key: any) => {
      Vue.set(state, key, initialState[key])
    })
  },
  setCreditCardAlert(state, val) {
    Vue.set(state, 'creditCardAlert', val)
  },

  setConfirm(state, data) {
    Vue.set(state, 'confirm', data)
    Vue.set(state, 'current', 'confirm')
  },

  setConfirmInputValue(state, value: string) {
    Vue.set(state.confirm, 'inputValue', value)
  },

  setConfirmError(state, value: string) {
    Vue.set(state.confirm, 'error', value)
  },

  setProfileEdit(state) {
    Vue.set(state, 'current', 'profile')
  },

  setPaymentEstimates(state, planId) {
    Vue.set(state.paymentEstimates, 'planId', planId)
    Vue.set(state, 'current', 'paymentEstimates')
  },

  setProfileImage(state, image: string) {
    Vue.set(state.profileEditor, 'image', image)
  },

  setProfileImageId(state, imageId: string) {
    Vue.set(state.profileEditor, 'profileImageId', imageId)
  },

  setProfileName(state, name: string) {
    Vue.set(state.profileEditor, 'name', name)
  },

  setProfileNetworkName(state, networkName: string) {
    Vue.set(state.profileEditor, 'networkName', networkName)
  },

  setProfileEmail(state, email: string) {
    Vue.set(state.profileEditor, 'email', email)
  },

  setProfileBio(state, bio: string) {
    Vue.set(state.profileEditor, 'bio', bio)
  },

  setProfileId(state, id: string) {
    Vue.set(state.profileEditor, 'id', id)
  },

  setTagEdit(state, tagId) {
    if (tagId) {
      Vue.set(state.tagEdit, 'tagId', tagId)
    }
    Vue.set(state, 'current', 'tagEdit')
  },

  setTagId(state, tagId) {
    Vue.set(state.tagEdit, 'tagId', tagId)
  },

  setTagEditOnSave(state, fn?: () => void) {
    Vue.set(state, 'tagEditOnSave', fn)
  },

  setTagFont(state, fontIndex: number) {
    Vue.set(state.tagEdit.data, 'fontStyle', fontIndex)
  },

  setTagShareable(state, shareable: boolean) {
    Vue.set(state.tagEdit, 'shareable', shareable)
  },

  setTagSaveable(state, saveable: boolean) {
    Vue.set(state.tagEdit, 'saveable', saveable)
  },
  setAudiogramEdit(state) {
    Vue.set(state, 'current', 'audiogramEdit')
  },
  setAudiogramOpenFrom(state, orientation: any) {
    Vue.set(state, 'audiogramOpenFrom', orientation)
  },
  setNewTagOpenFrom(state, orientation: any) {
    Vue.set(state, 'newTagOpenFrom', orientation)
  },

  setWindowOrientation(state, orientation: any) {
    Vue.set(state, 'windowOrientation', orientation)
  },

  setAudiogram(state, audiogram: any) {
    Vue.set(state, 'audiogram', audiogram)
  },
  clearTagEdit(state) {
    Vue.set(state, 'tagEdit', getEmptyTagModalData())
    Vue.set(state, 'windowOrientation', '')
    // Vue.set(state, 'newTagOpenFrom', '')
  },
  clearAudiogramEdit(state) {
    Vue.set(state, 'audiogram', {})
    Vue.set(state, 'audiogramOpenFrom', '')
  },

  setTagType(state, tagType: string) {
    if (get(state.tagEdit, 'data.image')) {
      Vue.set(
        state.tagEdit.data,
        'image',
        state.tagEdit.data.image.startsWith('/img') || state.tagEdit.data.image.startsWith('/src')
          ? TagImages[tagType]
          : state.tagEdit.data.image
      )
    }

    if (get(state.tagEdit, 'data.video')) {
      Vue.set(state.tagEdit.data, 'video', state.tagEdit.data.video)
    }
    Vue.set(state.tagEdit, 'tagType', tagType)
  },

  setTagImage(state, img) {
    Vue.set(state.tagEdit.data, 'image', img)
  },

  setTimelinePosition(state, millis: number) {
    Vue.set(state, 'timelinePosition', millis)
  },

  unSetTimelinePosition() {
    Vue.set(state, 'timelinePosition', null)
  },

  setTagVideo(state, video) {
    Vue.set(state.tagEdit.data, 'video', video)
  },

  setTagImageBlob(state, blob) {
    Vue.set(state.tagEdit.data, 'imageBlob', blob)
  },

  setTagImageId(state, imageId) {
    Vue.set(state.tagEdit.data, 'imageId', imageId)
  },

  setTagVideoId(state, videoId) {
    Vue.set(state.tagEdit.data, 'videoId', videoId)
  },

  setTagVideoSrc(state, src) {
    Vue.set(state.tagEdit.data, 'videoSrc', src)
  },

  setTagContactId(state, contactId) {
    Vue.set(state.tagEdit.data, 'contactId', contactId)
  },

  setTagLocationId(state, locationId) {
    Vue.set(state.tagEdit.data, 'locationId', locationId)
  },

  setTagPollId(state, pollId) {
    Vue.set(state.tagEdit.data, 'pollId', pollId)
  },

  setTagBuyId(state, buyId) {
    Vue.set(state.tagEdit.data, 'buyId', buyId)
  },

  setARTagId(state, buyId) {
    Vue.set(state.tagEdit.data, 'artagId', buyId)
  },

  setTagCaption(state, caption: string) {
    Vue.set(state.tagEdit.data, 'caption', caption)
  },

  setTagTopMargin(state, value: number) {
    Vue.set(state.tagEdit.data, 'topMargin', value)
  },

  setTagInputValues(state, values: string[]) {
    Vue.set(state.tagEdit.data, 'inputValues', values)
  },

  setTagEditShareable(state, shareable: boolean) {
    Vue.set(state.tagEdit, 'shareable', shareable)
  },

  setTagEditSaveable(state, saveable: boolean) {
    Vue.set(state.tagEdit, 'saveable', saveable)
  },

  setMapPlace(state, place: any) {
    Vue.set(state.tagEdit.data.location, 'place', place)
  },

  setEffectId(state, id: string) {
    Vue.set(state.tagEdit.data, 'effectId', id)
  },
  setCanvasData(state, canvas) {
    Vue.set(state.tagEdit.data, 'canvasData', canvas)
  },
  setTagOrientation(state, orientation) {
    Vue.set(state.tagEdit.data, 'orientation', orientation)
  },
  setSuggestedTag(state, value) {
    Vue.set(state.tagEdit, 'suggestedTag', value)
  },
  setVideoCoords(state, value) {
    Vue.set(state.tagEdit.data, 'videoCoords', value)
  },
  setTagTitle(state, value) {
    Vue.set(state.tagEdit, 'tagTitle', value)
  },

  //  -------------------------------------------------------------------------------
  //   AUDIO ADS
  //   --------------------------------------------------------------------------------
  setAudioAdProviderKind(state, kind: string) {
    Vue.set(state.tagEdit.data.audioAdMeta.provider, 'kind', kind)
  },

  setAudioAdCategories(state, categoryArr) {
    Vue.set(state, 'iabContentCategories', categoryArr)
  },
  setAudioAdDaast(state, daastArray: {}[]) {
    Vue.set(state, 'daastValues', daastArray)
  },

  setIabDaastValue(state, daastValues) {
    Vue.set(state, 'daastValues', daastValues)
  },

  setAudioAdNameValue(state, value: string) {
    Vue.set(state.tagEdit.data.audioAdMeta, 'caption', value)
    // This needs to be checked
    Vue.set(state.tagEdit.data.audioAdMeta, 'description', value)
  },
  setAudioAdGeoTargets(state, targetValue: [{ country: string }]) {
    Vue.set(state.tagEdit.data.audioAdMeta, 'geoTargets', targetValue)
  },

  setAudioAdMaxDurationSeconds(state, value: number) {
    Vue.set(state.tagEdit.data.audioAdMeta.provider, 'maxDurationSeconds', value)
  },

  setAudioAdUid(state, uid: string) {
    Vue.set(state.tagEdit.data.audioAdMeta.provider, 'audioTrackUid', uid)
  },

  setCampaignTags(state, value: {}[]) {
    Vue.set(state.tagEdit.data.audioAdMeta, 'campaignTags', value)
  },

  setCampaignId(state, id) {
    Vue.set(state, 'campaignId', id)
  },

  setAudioAdCAPValue(state, value: number) {
    Vue.set(state.tagEdit.data.audioAdMeta, 'maxServes', value)
  },

  setAudioAdForeverOption(state, value: string) {
    Vue.set(state.tagEdit.data.audioAdMeta, 'frequency', value)
  },

  setAudioAdStartDateValue(state, value: string) {
    Vue.set(state.tagEdit.data.audioAdMeta, 'startTime', value)
  },

  setAudioAdEndDateValue(state, value: string) {
    Vue.set(state.tagEdit.data.audioAdMeta, 'endTime', value)
  },

  setDisplayTagAdId(state, adId) {
    Vue.set(state.tagEdit.data, 'displayAdId', adId)
  },

  setDisplayTagAdMeta(state, value: any) {
    Vue.set(state.tagEdit.data, 'displayAdMeta', value)
  },

  setDisplayAdGeoTargets(state, value: [{ country: string }]) {
    Vue.set(state.tagEdit.data.displayAdMeta.defaultCampaign, 'geoTargets', value)
  },

  setDisplayAdKeywordValues(state, value: string) {
    Vue.set(state.tagEdit.data.displayAdMeta.defaultCampaign, 'keywords', value)
  },

  setDisplayAdForeverOption(state, value: string) {
    Vue.set(state.tagEdit.data.displayAdMeta.defaultCampaign, 'frequency', value)
  },

  setDisplayAdServeOption(state, value: string) {
    Vue.set(state.tagEdit.data.displayAdMeta, 'adServiceSource', value)
  },

  setDisplayAdStartDateValue(state, value: string) {
    Vue.set(state.tagEdit.data.displayAdMeta.defaultCampaign, 'startTime', value)
  },

  setDisplayAdEndDatevalue(state, value: string) {
    Vue.set(state.tagEdit.data.displayAdMeta.defaultCampaign, 'endTime', value)
  },

  setVideoTagAdId(state, adId) {
    Vue.set(state.tagEdit.data, 'videoAdId', adId)
  },

  setVideoTagAdMeta(state, value: any) {
    Vue.set(state.tagEdit.data, 'videoAdMeta', value)
  },

  setVideoAdGeoTargets(state, value: [{ country: string }]) {
    Vue.set(state.tagEdit.data.videoAdMeta.defaultCampaign, 'geoTargets', value)
  },

  setVideoAdKeywordValues(state, value: string) {
    Vue.set(state.tagEdit.data.videoAdMeta.defaultCampaign, 'keywords', value)
  },

  setVideoAdForeverOption(state, value: string) {
    Vue.set(state.tagEdit.data.videoAdMeta.defaultCampaign, 'frequency', value)
  },

  setVideoAdStartDateValue(state, value: string) {
    Vue.set(state.tagEdit.data.videoAdMeta.defaultCampaign, 'startTime', value)
  },

  setVideoAdEndDatevalue(state, value: string) {
    Vue.set(state.tagEdit.data.videoAdMeta.defaultCampaign, 'endTime', value)
  },

  setAudioTrackCreator(state) {
    Vue.set(state, 'current', 'audioTrackCreate')
  },

  setAudioTrackEditor(state) {
    Vue.set(state, 'current', 'audioTrackEdit')
  },

  setAudioTrackAdsEditor(state) {
    Vue.set(state, 'current', 'audioTrackAdsEdit')
  },

  setAudioTrackPlayer(state, audioTrackUid) {
    Vue.set(state.audioTrackPlayer, 'uid', audioTrackUid)
    Vue.set(state, 'current', 'audioTrackPlay')
  },

  setAudioAddPlayer(State) {
    Vue.set(state.audioTrackPlayer, 'source', 'ads')
  },

  setAudioSelect(state) {
    Vue.set(state, 'current', 'audioSelect')
  },

  setTranscript(state, transcript: any) {
    Vue.set(state.transcript.transcriptsByAudioUid, transcript.audioUid, transcript.sentences)
    Vue.set(state.transcript, 'audioUid', transcript.audioUid)
    Vue.set(state, 'current', 'transcript')
  },

  setYoutubeSetting(state) {
    Vue.set(state, 'current', 'youtubePublish')
  },

  setAudioTrackUid(state, uid: string) {
    Vue.set(state.audioTrackEditor, 'uid', uid)
  },

  setAudioTrackName(state, name: string) {
    Vue.set(state.audioTrackEditor, 'name', name)
  },

  setAudioTrackLanguage(state, language: string) {
    Vue.set(state.audioTrackEditor, 'language', language)
  },

  setAudioTrackDescription(state, description: string) {
    Vue.set(state.audioTrackEditor, 'description', description)
  },

  setAudioTrackImageId(state, imageId: string) {
    Vue.set(state.audioTrackEditor, 'imageId', imageId)
  },

  setAudioTrackSummary(state, summary: string) {
    Vue.set(state.audioTrackEditor, 'summary', summary)
  },

  setAudioTrackExplicitContent(state, explicitContent: boolean) {
    Vue.set(state.audioTrackEditor, 'explicitContent', explicitContent)
  },

  setAudioTrackKeywords(state, keywords: string[]) {
    Vue.set(state.audioTrackEditor, 'keywords', keywords)
  },

  setAudioTrackAdsUid(state, uid: string) {
    Vue.set(state.audioTrackAdsEditor, 'uid', uid)
  },

  setAudioTrackAdsName(state, name: string) {
    Vue.set(state.audioTrackAdsEditor, 'name', name)
  },

  setAudioTrackAdsDurationMillis(state, durationMillis: any) {
    Vue.set(state.audioTrackAdsEditor, 'durationMillis', durationMillis)
  },

  setBuyProductName(state, value: string) {
    Vue.set(state.tagEdit.data.buyMeta, 'name', value)
  },

  setBuyActionUrl(state, value: string) {
    Vue.set(state.tagEdit.data.buyMeta, 'actionUrl', value)
  },

  setBuyActionText(state, value: string) {
    Vue.set(state.tagEdit.data.buyMeta, 'actionText', value)
  },

  setBuyMeta(state, value: any) {
    Vue.set(state.tagEdit.data, 'buyMeta', value)
  },

  setARTagName(state, value: string) {
    Vue.set(state.tagEdit.data.artagMeta, 'name', value)
  },

  setARTagAssetUrl(state, value: string) {
    Vue.set(state.tagEdit.data.artagMeta, 'assetUrl', value)
  },

  setARTagMeta(state, value: any) {
    Vue.set(state.tagEdit.data, 'artagMeta', value)
  },

  setExportInsightsLevel(state, level: any) {
    Vue.set(state.exportInsights, 'level', level)
  },

  setExportInsightsId(state, id: any) {
    Vue.set(state.exportInsights, 'id', id)
  },

  setExportInsightsName(state, name: string) {
    Vue.set(state.exportInsights, 'name', name)
  },

  setAudioPreview(state, episodeUid: string) {
    Vue.set(state.audioPreview, 'episodeUid', episodeUid)
    Vue.set(state, 'current', 'audioPreview')
  },

  setShowTagModal(state, tagId: string) {
    Vue.set(state.showTagModal, 'tagId', tagId)
    Vue.set(state, 'current', 'showTagModal')
  },

  setIframeEmbed(state, episodeUid: string) {
    Vue.set(state.iframeEmbed, 'episodeUid', episodeUid)
    Vue.set(state, 'current', 'iframeEmbed')
  },

  remove(state) {
    Vue.set(state, 'current', null)
  },
  close(state) {
    Vue.set(state, 'current', '')
  },

  setCreatePodcast(state) {
    Vue.set(state, 'current', 'createPodcast')
  },

  setAddMember(state) {
    // Vue.set(state.addMember, 'podcastUid', podcastUid)
    Vue.set(state, 'current', 'addMember')
  },

  setAddShowMember(state, podcastUid: string) {
    Vue.set(state.addShowMember, 'podcastUid', podcastUid)
    Vue.set(state, 'current', 'addShowMember')
  },

  setMembers(state, podcastUid: string) {
    Vue.set(state.setMembers, 'podcastUid', podcastUid)
    Vue.set(state, 'current', 'setMembers')
  },

  setEditPodcast(state, podcastUid: string) {
    Vue.set(state.podcastEditor, 'podcastUid', podcastUid)
    Vue.set(state, 'current', 'editPodcast')
  },

  setAddEpisode(state, podcastUid) {
    Vue.set(state.podcastEditor, 'podcastUid', podcastUid)
    Vue.set(state, 'current', 'addEpisode')
  },

  setImportPodcast(state) {
    Vue.set(state, 'current', 'importPodcast')
  },

  setPublishPodcast(state, podcastUid: string) {
    Vue.set(state.podcastEditor, 'podcastUid', podcastUid)
    Vue.set(state, 'current', 'publishPodcast')
  },

  setEditPodcastEpisode(state, episodeUid: string) {
    Vue.set(state.podcastEpisodeEditor, 'episodeUid', episodeUid)
    Vue.set(state, 'current', 'editEpisode')
  },

  setNoMemberNetworks(state) {
    Vue.set(state, 'current', 'noMemberNetworks')
  },

  setExportInsights(state) {
    Vue.set(state, 'current', 'exportInsights')
  },

  setReplaceAudio(state, episodeUid) {
    Vue.set(state.replaceAudio, 'episodeUid', episodeUid)
    Vue.set(state, 'current', 'replaceAudio')
  },

  setUpdateAudioAd(state, ad) {
    Vue.set(state, 'audioAdEdit', ad)
    Vue.set(state, 'current', 'AudioAdEdit')
  },

  setPreviewAd(state, ad) {
    Vue.set(state, 'audioAdEdit', ad)
    Vue.set(state, 'current', 'AudioAdPlay')
  },

  setCurrentModal(state, modalName) {
    Vue.set(state, 'current', modalName)
  },

  setAudioAdPreview(state) {
    Vue.set(state, 'current', 'audioAdPreview')
  },

  setYTSetting(state) {
    Vue.set(state, 'current', 'youtubeSetting')
  },
  setYTBatchSetting(state) {
    Vue.set(state, 'current', 'youtubeBatchSetting')
  },

  setSubtitleStyle(state) {
    Vue.set(state, 'current', 'subtitleStyle')
  },

  setAutoPublishSetting() {
    Vue.set(state, 'current', 'autoPublishSetting')
  },
  setTagPreview(state, setting: any) {
    Vue.set(state.tagPreview, 'img', setting.img)
    Vue.set(state.tagPreview, 'saveable', setting.saveable)
    Vue.set(state.tagPreview, 'shareable', setting.shareable)
    Vue.set(state.tagPreview, 'orientation', setting.orientation)
    Vue.set(state.tagPreview, 'tagType', setting.tagType)
  },

  setIsDuplicate(state, isDuplicate) {
    Vue.set(state, 'isDuplicate', isDuplicate)
  },
}

const actions: ActionTree<any, any> = {
  resetModalState(context) {
    context.commit('resetState')
  },

  toggleCreditCardAlert(context, value) {
    context.commit('setCreditCardAlert', value)
  },

  async showConfirm(context, data) {
    context.commit('setConfirm', data)
  },

  async setConfirmInputValue(context, value: string) {
    context.commit('setConfirmInputValue', value)
  },

  async setConfirmError(context, value: string) {
    context.commit('setConfirmError', value)
  },

  async showProfileEdit(context, profile: any) {
    context.dispatch('loadProfileEditDetails', profile)
    context.commit('setProfileEdit')
  },

  async showReplaceAudio(context, episodeUid) {
    context.commit('setReplaceAudio', episodeUid)
  },

  async showPaymentEstimates(context, planId) {
    context.commit('setPaymentEstimates', planId)
  },

  async loadProfileEditDetails(context, profile: any) {
    context.commit('setProfileId', profile.id)
    context.commit('setProfileImageId', profile.profileImageId)
    context.commit('setProfileName', profile.name)
    context.commit('setProfileNetworkName', context.getters.currentNetwork.network.name)
    context.commit('setProfileEmail', profile.email)
    context.commit('setProfileBio', profile.bio)
  },

  async changeProfileImage(context, image: string) {
    context.commit('setProfileImage', image)
  },

  async changeProfileImageId(context, imageId: string) {
    context.commit('setProfileImageId', imageId)
  },

  async changeProfileName(context, name: string) {
    context.commit('setProfileName', name)
  },

  async changeProfileNetworkName(context, networkName: string) {
    context.commit('setProfileNetworkName', networkName)
  },

  async changeProfileBio(context, bio: string) {
    context.commit('setProfileBio', bio)
  },

  async uploadProfileDetails(context, profile: any) {
    await AdoriService.updateProfile({
      id: profile.id,
      bio: profile.bio,
      name: profile.name,
      profileImageId: profile.profileImageId,
    })
    context.dispatch('updateProfileInfo', profile)
  },

  // Depricated
  async uploadProfileNetworkDetails(context, networkName: string) {
    const networkId = context.getters.networkId
    await AdoriService.updateProfileNetwork(networkId, {
      name: networkName,
    })
    context.dispatch('updateProfileNetworkInfo', networkName)
  },

  async showTagEdit(context, tagId?: string) {
    await context.dispatch('selectUniqueItem', null)
    const tag = tagId && context.getters.tag(tagId)
    context.commit('clearTagEdit')
    if (tag) await context.dispatch('loadTagEdit', tag)
    context.commit('setTagEdit', tagId)
  },

  async showAudiogramEdit(context, audiogramId?: any) {
    if (audiogramId) context.commit('setAudiogram', context.rootGetters.audiogramById[audiogramId])
    context.commit('setAudiogramEdit')
  },

  setIsDuplicate(context, isDuplicate: boolean) {
    context.commit('setIsDuplicate', isDuplicate)
  },

  audiogramOpenFrom(context, orientation?: string) {
    context.commit('setAudiogramOpenFrom', orientation)
  },
  newTagOpenFrom(context, orientation?: string) {
    context.commit('setNewTagOpenFrom', orientation)
  },

  windowOrientation(context, orientation?: string) {
    context.commit('setWindowOrientation', orientation)
  },

  async loadAudiogramEdit(context, audiogramId) {
    const networkId = context.getters.networkId
    const audiogram = await AdoriServicev6.get_audiogram_by_Id(networkId, audiogramId)
    context.commit('setAudiogram', audiogram)
  },

  async clearAudiogramEdit(context) {
    context.commit('clearAudiogramEdit')
  },

  async showTagSuggestion(context, tag: object) {
    await context.dispatch('selectUniqueItem', null)
    context.commit('clearTagEdit')
    await context.dispatch('loadTagEdit', tag)
    context.commit('setTagEdit')
  },

  async setTagEditOnSave(context, fn?: () => void) {
    context.commit('setTagEditOnSave', fn)
  },

  async changeTagShareable(context, shareable: boolean) {
    context.commit('setTagShareable', shareable)
  },

  async changeTagSaveable(context, saveable: boolean) {
    context.commit('setTagSaveable', saveable)
  },

  async loadTagEdit(context, tag) {
    const tagModalData = getTagModalData(tag)

    context.commit('setTagType', tagModalData.tagType)
    context.commit('setTagImageId', tagModalData.data.imageId)
    context.commit('setTagImage', tagModalData.data.image)
    context.commit('setTagVideo', tagModalData.data.video)
    context.commit('setTagVideoId', tagModalData.data.videoId)
    context.commit('setTagCaption', tagModalData.data.caption)
    context.commit('setTagFont', tagModalData.data.fontStyle)
    context.commit('setTagInputValues', tagModalData.data.inputValues)
    context.commit('setTagTopMargin', tagModalData.data.topMargin)
    context.commit('setTagEditSaveable', tagModalData.saveable)
    context.commit('setTagEditShareable', tagModalData.shareable)
    context.commit('setTagPollId', tagModalData.data.pollId)
    context.commit('setTagContactId', tagModalData.data.contactId)
    context.commit('setTagLocationId', tagModalData.data.locationId)
    context.commit('setDisplayTagAdMeta', tagModalData.data.displayAdMeta)
    context.commit('setVideoTagAdMeta', tagModalData.data.videoAdMeta)
    context.commit('setBuyMeta', tagModalData.data.buyMeta)
    context.commit('setARTagMeta', tagModalData.data.artagMeta)
    context.commit('setEffectId', tagModalData.data.effectId)
    context.commit('setCanvasData', tagModalData.data.canvasData)
    context.commit('setTagOrientation', tagModalData.data.orientation)
    context.commit('setSuggestedTag', tagModalData.suggestedTag)
    context.commit('setVideoCoords', tagModalData.data.videoCoords)
    context.commit('setTagTitle', tagModalData.tagTitle)
  },

  async showAudioTrackCreator(context) {
    context.dispatch('loadAudioTrackEditor', {
      name: '',
      description: '',
      imageId: '',
    })
    context.commit('setAudioTrackCreator')
  },

  async showAudioTrackEditor(context, audioTrack: any) {
    context.dispatch('loadAudioTrackEditor', audioTrack)
    context.commit('setAudioTrackEditor')
  },

  async showAudioTrackAdsEditor(context, audioTrack: any) {
    context.dispatch('loadAudioTrackAdsEditor', audioTrack)
    context.commit('setAudioTrackAdsEditor')
  },

  async showAudioTrackPlayer(context, audioTrackUid) {
    context.commit('setAudioTrackPlayer', audioTrackUid)
  },

  async showAudioAddPlayer(context, addUid) {
    context.commit('setAudioAddPlayer')
    context.commit('setAudioTrackPlayer', addUid)
  },

  async showExportInsights(context, { level, id, name }) {
    context.dispatch('loadExportInsights', { level, id, name })
    context.commit('setExportInsights')
  },

  async cropImage(context, { imageBlob, imageDataUrl }) {
    context.commit('setTagImage', imageDataUrl)
    context.commit('setTagImageBlob', imageBlob)
    context.commit('setTagImageId', '')
  },

  async loadAudioTrackEditor(context, audioTrack) {
    context.commit('setAudioTrackUid', audioTrack.uid)
    context.commit('setAudioTrackName', audioTrack.name)
    context.commit('setAudioTrackDescription', audioTrack.description)
    context.commit('setAudioTrackImageId', audioTrack.imageId)
    context.commit('setAudioTrackSummary', audioTrack.summary)
    context.commit('setAudioTrackExplicitContent', audioTrack.explicitContent)
    context.commit('setAudioTrackKeywords', audioTrack.keywords || [])
    context.commit('setAudioTrackKeywords', audioTrack.keywords || [])
    context.commit('setAudioTrackLanguage', audioTrack.language)
  },

  async loadAudioTrackAdsEditor(context, audioTrack) {
    context.commit('setAudioTrackAdsUid', audioTrack.uid)
    context.commit('setAudioTrackAdsName', audioTrack.name)
    context.commit('setAudioTrackAdsDurationMillis', audioTrack.durationMillis)
  },

  async loadExportInsights(context, { level, id, name }) {
    context.commit('setExportInsightsLevel', level)
    context.commit('setExportInsightsId', id)
    context.commit('setExportInsightsName', name)
  },

  async showAudioSelect(context) {
    context.commit('setAudioSelect')
  },

  async changeTagType(context, tagType: string) {
    context.commit('setTagType', tagType)
  },

  async changeTagImage(context, img) {
    context.commit('setTagImage', img)
  },

  async changeTagImageId(context, imageId) {
    context.commit('setTagImageId', imageId)
  },

  async changeTagVideo(context, video) {
    context.commit('setTagVideo', video)
  },

  async changeTagVideoSrc(context, src: string) {
    context.commit('setTagVideoSrc', src)
  },

  async changeTagVideoId(context, id: string) {
    context.commit('setTagVideoId', id)
  },

  async changeTagTopMargin(context, topMargin: number) {
    context.commit('setTagTopMargin', topMargin)
  },

  async changeTagInputValues(context, values: string[]) {
    context.commit('setTagInputValues', values)
  },

  async changeTagFont(context, fontIndex: number) {
    context.commit('setTagFont', fontIndex)
  },

  async changeTagCaption(context, caption: string) {
    context.commit('setTagCaption', caption)
  },

  async changeMapPlace(context, place: any) {
    context.commit('setMapPlace', place)
  },

  async changeAudioAdProviderKind(context, kind: string) {
    context.commit('setAudioAdProviderKind', kind)
  },

  async changesAudioAdUid(context, uid: string) {
    context.commit('setAudioAdUid', uid)
  },

  async changeAudioAdDaast(context, daastArray: {}[]) {
    context.commit('setAudioAdDaast', daastArray)
  },

  async changeAudioAdNameValue(context, value: string) {
    context.commit('setAudioAdNameValue', value)
  },

  async changeAudioGeoTargets(context, targetValue: [{ country: string }]) {
    context.commit('setAudioAdGeoTargets', targetValue)
  },

  async changeAudioAdDuration(context, value: number) {
    context.commit('setAudioAdMaxDurationSeconds', value)
  },

  async changeCampaign(context, value) {
    context.commit('setCampaignTags', value)
  },

  async changeAudioAdCAPValue(context, value: number) {
    context.commit('setAudioAdCAPValue', value)
  },

  async changeAudioAdForeverOption(context, value: boolean) {
    context.commit('setAudioAdForeverOption', value)
  },

  async changeAudioAdStartDateValue(context, value: string) {
    context.commit('setAudioAdStartDateValue', value)
  },

  async changeAudioAdEndDateValue(context, value: string) {
    context.commit('setAudioAdEndDateValue', value)
  },

  async changeDisplayAdForeverOption(context, value: boolean) {
    context.commit('setDisplayAdForeverOption', value)
  },

  async changeDisplayAdServeOption(context, value: boolean) {
    context.commit('setDisplayAdServeOption', value)
  },

  async changeDisplayAdGeoTargets(context, value: [{ country: string }]) {
    context.commit('setDisplayAdGeoTargets', value)
  },

  async changeDisplayAdKeywordValues(context, value: string) {
    context.commit('setDisplayAdKeywordValues', value)
  },

  async changeDisplayAdStartDateValue(context, value: string) {
    context.commit('setDisplayAdStartDateValue', value)
  },

  async changeDisplayAdEndDateValue(context, value: string) {
    context.commit('setDisplayAdEndDatevalue', value)
  },

  async changeVideoAdForeverOption(context, value: boolean) {
    context.commit('setVideoAdForeverOption', value)
  },

  async changeVideoAdServeOption(context, value: boolean) {
    context.commit('setVideoAdServeOption', value)
  },

  async changeVideoAdGeoTargets(context, value: [{ country: string }]) {
    context.commit('setVideoAdGeoTargets', value)
  },

  async changeVideoAdKeywordValues(context, value: string) {
    context.commit('setVideoAdKeywordValues', value)
  },

  async changeVideoAdStartDateValue(context, value: string) {
    context.commit('setVideoAdStartDateValue', value)
  },

  async changeVideoAdEndDateValue(context, value: string) {
    context.commit('setVideoAdEndDatevalue', value)
  },

  async changeBuyProductName(context, value: string) {
    context.commit('setBuyProductName', value)
  },

  async changeBuyActionUrl(context, value: string) {
    context.commit('setBuyActionUrl', value)
  },

  async changeBuyActionText(context, value: string) {
    context.commit('setBuyActionText', value)
  },

  async changeARTagName(context, value: string) {
    context.commit('setARTagName', value)
  },

  async changeARTagAssetUrl(context, value: string) {
    context.commit('setARTagAssetUrl', value)
  },

  async showNoMemberNetworks(context) {
    context.commit('setNoMemberNetworks')
  },

  async uploadTag(context, replace: boolean) {
    const networkId = context.getters.networkId
    const { tagType } = context.state.tagEdit
    if (
      context.state.tagEdit.data.image &&
      !context.state.tagEdit.data.image.includes('adorilabs') &&
      !context.state.placeHolderImages.includes(context.state.tagEdit.data.image) &&
      tagType !== 'audio' &&
      tagType !== 'display_ad' &&
      tagType !== 'video_ad'
    ) {
      await context.dispatch('uploadImage', 'setTagImageId')
    }

    if (tagType === 'call') {
      const oldContactId = context.state.tagEdit.data.contactId
      const replaceContact = replace && oldContactId
      const payload = {
        name: context.state.tagEdit.data.inputValues[0],
        phoneNumber: context.state.tagEdit.data.inputValues[1],
      }

      if (replaceContact) {
        await AdoriService.updateContact(networkId, oldContactId, payload)
      } else {
        const contactId = await AdoriService.createContact(networkId, payload).then((res: any) => res.id)
        context.commit('setTagContactId', contactId)
      }
    }

    if (tagType === 'poll') {
      const oldPollId = context.state.tagEdit.data.pollId
      const replacePoll = replace && oldPollId
      const choicesArray = context.state.tagEdit.data.inputValues.slice(1).filter((ele: any) => ele)

      let choices: any = {}

      for (let i = 0; i < choicesArray.length; i++) {
        let key = `choice${i + 1}`
        choices[key] = choicesArray[i]
      }

      const payload = {
        topic: context.state.tagEdit.data.inputValues[0],
        ...choices,
      }

      if (replacePoll) {
        await AdoriService.updatePoll(networkId, oldPollId, payload)
      } else {
        const pollId = await AdoriService.createPoll(networkId, payload).then((res: any) => res.id)
        context.commit('setTagPollId', pollId)
      }
    }

    if (tagType === 'location') {
      const oldLocationId = context.state.tagEdit.data.locationId
      const replaceLocation = replace && oldLocationId
      const place = context.state.tagEdit.data.location.place
      const createOrUpdateTag = !(oldLocationId && !place)

      if (createOrUpdateTag) {
        if (!place) {
          store.dispatch('pushNotification', {
            text: 'Invalid location is given.',
            type: 'ERROR',
          })
          return
        }
        const lat = place.geometry.location.lat().toString()
        const lng = place.geometry.location.lng().toString()
        const location = `geo:${lat},${lng}`
        let locationName: string = ''
        const formatedAdd = context.state.tagEdit.data.location.place.formatted_address
        const name = context.state.tagEdit.data.location.place.name
        if (formatedAdd.startsWith(name)) {
          locationName = formatedAdd
        } else {
          locationName = name + ', ' + formatedAdd
        }
        const payload = {
          location,
          name: locationName,
        }

        if (replaceLocation) {
          await AdoriService.updateLocation(networkId, oldLocationId, payload)
        } else {
          const locationId = await AdoriService.createLocation(networkId, payload).then((res: any) => res.id)
          context.commit('setTagLocationId', locationId)
        }
      }
    }

    if (tagType === 'display_ad') {
      const oldAdId = context.state.tagEdit.data.displayAdId
      const replaceAdId = replace && oldAdId

      let keywords: any = get(context, 'state.tagEdit.data.displayAdMeta.defaultCampaign.keywords')
      if (isEmpty(keywords[0])) {
        keywords = []
      }

      const geo: any = get(context, 'state.tagEdit.data.displayAdMeta.defaultCampaign.geoTargets')
      let geoTargets: any = []
      geo.forEach((element: any) => {
        geoTargets.push(element.country)
      })

      let sizes: any = null
      if (get(context, 'state.tagEdit.data.displayAdMeta.defaultCampaign.sizes.length') !== 0) {
        sizes = JSON.parse(context.state.tagEdit.data.displayAdMeta.defaultCampaign.sizes)
      }

      const payload = {
        url: '', // TODO: SAMAR, to be removed. Is a required field on server.
        adServiceSource: context.state.tagEdit.data.displayAdMeta.adServiceSource,
        defaultCampaign: {
          priority: context.state.tagEdit.data.displayAdMeta.defaultCampaign.priority,
          startTime: moment(context.state.tagEdit.data.displayAdMeta.defaultCampaign.startTime, 'YYYY-MM-DD'),
          endTime: moment(context.state.tagEdit.data.displayAdMeta.defaultCampaign.endTime, 'YYYY-MM-DD'),
          keywords: keywords,
          sizes: sizes,
          geoTargets: geoTargets,
          maxServes: context.state.tagEdit.data.displayAdMeta.defaultCampaign.maxServes,
          frequency: context.state.tagEdit.data.displayAdMeta.defaultCampaign.frequency,
        },
      }

      if (replaceAdId) {
        await AdoriServicev6.updateDisplayAd(networkId, replaceAdId, payload)
      } else {
        const adId = await AdoriServicev6.createDisplayAd(networkId, payload).then((res: any) => res.id)
        context.commit('setDisplayTagAdId', adId)
      }
    }

    if (tagType === 'video_ad') {
      const oldAdId = context.state.tagEdit.data.videoAdId
      const replaceAdId = replace && oldAdId

      let keywords: any = get(context, 'state.tagEdit.data.videoAdMeta.defaultCampaign.keywords')
      if (isEmpty(keywords[0])) {
        keywords = []
      }

      const geo: any = get(context, 'state.tagEdit.data.videoAdMeta.defaultCampaign.geoTargets')
      let geoTargets: any = []
      geo.forEach((element: any) => {
        geoTargets.push(element.country)
      })

      let sizes: any = null
      if (get(context, 'state.tagEdit.data.videoAdMeta.defaultCampaign.sizes.length') !== 0) {
        sizes = JSON.parse(context.state.tagEdit.data.videoAdMeta.defaultCampaign.sizes)
      }

      const payload = {
        defaultCampaign: {
          priority: context.state.tagEdit.data.videoAdMeta.defaultCampaign.priority,
          startTime: moment(context.state.tagEdit.data.videoAdMeta.defaultCampaign.startTime, 'YYYY-MM-DD'),
          endTime: moment(context.state.tagEdit.data.videoAdMeta.defaultCampaign.endTime, 'YYYY-MM-DD'),
          keywords: keywords,
          sizes: sizes,
          geoTargets: geoTargets,
          maxServes: context.state.tagEdit.data.videoAdMeta.defaultCampaign.maxServes,
          frequency: context.state.tagEdit.data.videoAdMeta.defaultCampaign.frequency,
        },
      }

      if (replaceAdId) {
        await AdoriServicev6.updateVideoAd(networkId, replaceAdId, payload)
      } else {
        const adId = await AdoriServicev6.createVideoAd(networkId, payload).then((res: any) => res.id)
        context.commit('setVideoTagAdId', adId)
      }
    }

    if (tagType === 'buy') {
      const oldBuyId = context.state.tagEdit.data.buyId
      const replaceBuy = replace && oldBuyId
      const payload = {
        name: context.state.tagEdit.data.buyMeta.name,
        actionUrl: context.state.tagEdit.data.buyMeta.actionUrl,
        actionText: context.state.tagEdit.data.buyMeta.actionText,
      }

      if (replaceBuy) {
        await AdoriServicev6.updateBuyTag(networkId, oldBuyId, payload)
      } else {
        const buyId = await AdoriServicev6.createBuyTag(networkId, payload).then((res: any) => res.id)
        context.commit('setTagBuyId', buyId)
      }
    }

    if (tagType === 'artag') {
      const oldARTagId = context.state.tagEdit.data.artagId
      const replaceARTag = replace && oldARTagId
      const payload = {
        name: context.state.tagEdit.data.artagMeta.name,
        assetUrl: context.state.tagEdit.data.artagMeta.assetUrl,
      }

      if (replaceARTag) {
        await AdoriServicev6.updateARTag(networkId, oldARTagId, payload)
      } else {
        const tagId = await AdoriServicev6.createARTag(networkId, payload).then((res: any) => res.id)
        context.commit('setARTagId', tagId)
      }
    }

    const payload = getTagUploadBody({ ...context.state.tagEdit, ...context.state.tagEdit.data }, replace)

    if (tagType === 'audio' || tagType === 'display_ad' || tagType === 'video_ad') {
      payload['imageId'] = null
      payload['imageData'] = null
      payload['canvasData'] = null
      payload['videoId'] = null
      payload['videoCoords'] = null
    }

    const oldTagId = context.state.tagEdit.tagId

    let tag: any
    if (replace) {
      payload['effectId'] = context.state.tagEdit.data.effectId
      tag = await AdoriService.updateTag(networkId, oldTagId, payload)
      context.commit('addTag', tag)
    } else {
      payload['effectId'] = context.state.tagEdit.data.effectId
      tag = await AdoriService.createTag(networkId, payload)
      context.commit('addTag', tag)
      context.commit('addTagId', tag.id)
      context.commit('recentTagId', tag.id)
      return tag.id
    }

    const collectionName = context.getters.itemSelected
    if (collectionName) {
      context.dispatch('addTagsToTagCollection', {
        collectionName,
        tagIds: [tag.id],
      })
    }
  },

  async updateAudioTrack(context, { audioUid, payload }) {
    const networkId = context.getters.networkId
    const audio = await AdoriService.updateAudio(networkId, audioUid, payload)
    context.dispatch('addAudio', audio)
  },

  async uploadNewAudioTrack(context) {
    context.dispatch('showFileUploader', {
      accept: 'audio/*,video/ogg,video/mp4,video/webm,video/quicktime',
      onChange: async () => {
        context.dispatch('uploadAudio')
        context.dispatch('showAudioTrackCreator')
      },
      multiple: false,
    })
  },

  async uploadNewEpisode(context) {
    context.dispatch('showFileUploader', {
      accept: 'audio/*,video/ogg,video/mp4,video/webm,video/quicktime',
      onChange: async () => {
        context.dispatch('uploadAudio')
      },
      multiple: false,
    })
  },
  async uploadEpisode(context) {
    context.dispatch('showFileUploader', {
      accept: 'audio/*,video/ogg,video/mp4,video/webm,video/quicktime',
      onChange: async () => {},
      multiple: false,
    })
  },
  async uploadRawAudio(context) {
    context.dispatch('showFileUploader', {
      accept: 'audio/mp3, audio/wav, audio/ogg, audio/m4a',
      onChange: async () => {},
      multiple: false,
    })
  },

  async uploadNewEpisodeUsingText(context) {
    context.dispatch('showFileUploader', {
      accept: '.txt,.pdf',
      onChange: async () => {},
      multiple: false,
    })
  },

  async uploadNewAd(context) {
    context.dispatch('showFileUploader', {
      accept: 'audio/*,video/ogg,video/mp4,video/webm,video/quicktime',
      onChange: async () => {
        context.dispatch('uploadAudio', 'AD')
      },
      multiple: false,
    })
  },

  async showTranscript(context, audioUid: string) {
    const networkId = context.getters.networkId
    // const transcript = context.getters.getTranscript(audioUid) ||
    //   await AdoriService.fetchAudioTranscript(networkId, audioUid).then((res: any) => res.sentences)
    const transcript = context.getters.getTranscript(audioUid) || null
    context.commit('setTranscript', { sentences: transcript, audioUid })
  },

  async showYoutubeSetting(context) {
    context.commit('setYoutubeSetting')
  },

  async closeModal(context) {
    context.commit('setAudioUploadProgress', 0)
    context.commit('remove')
  },
  async closeModalChange(context) {
    context.commit('setAudioUploadProgress', 0)
    context.commit('close')
  },

  async createAudioTrackFromObject(context, episode) {
    context.dispatch('addAudio', episode)
    context.dispatch('addAudioUid', episode.uid)
  },

  async createAudioTrack(context, payload) {
    const networkId = context.getters.networkId
    const audioTrack: any = await AdoriService.createAudio(networkId, {
      ...payload,
      public: true,
      uploadId: context.getters.audioUploadId,
    })
    if (Vue.prototype.$permissions.isViewEpisodeAllowed()) context.dispatch('createAudioTrackFromObject', audioTrack)
    return audioTrack
  },

  async createAudioTrackFromFile(context, payload) {
    const networkId = context.getters.networkId
    const audioTrack: any = await AdoriService.createAudioFromFile(networkId, payload)
    return audioTrack.id
  },

  async createAudioAd(context, payload) {
    const networkId = context.getters.networkId
    const audioAd: any = await AdoriService.createAudioAd(networkId, payload)
    return audioAd.id
  },

  showAudioPreview(context, episodeUid: string) {
    context.commit('setAudioPreview', episodeUid)
  },

  async showShowsEpisodesTagModal(context, tagId: string) {
    context.commit('setShowTagModal', tagId)
  },

  showIframeEmbed(context, episodeUid: string) {
    context.commit('setIframeEmbed', episodeUid)
  },

  async showCreatePodcast(context) {
    context.commit('setCreatePodcast')
  },

  async showAddMember(context) {
    context.commit('setAddMember')
  },

  async showAddShowMember(context, podcastUid: string) {
    context.commit('setAddShowMember', podcastUid)
  },

  async showMembers(context, podcastUid: string) {
    context.commit('setMembers', podcastUid)
  },

  async showEditPodcast(context, podcastUid: string) {
    context.commit('setEditPodcast', podcastUid)
  },

  async showAddEpisode(context, podcastUid: string) {
    context.commit('setAddEpisode', podcastUid)
  },

  async showImportPodcast(context) {
    context.commit('setImportPodcast')
  },

  async showPublishPodcast(context, podcastUid: string) {
    context.commit('setPublishPodcast', podcastUid)
  },

  async showEditPodcastEpisode(context, episodeUid: string) {
    context.commit('setEditPodcastEpisode', episodeUid)
  },

  async downloadTranscript(context, payload) {
    const networkId = context.getters.networkId
    const data: any = await AdoriService.downloadTranscript(networkId, payload)
    return data
  },

  async previewAudioAd(context, audioUid: string) {
    context.commit('setAudioAdPreview')
  },

  async showYTPublishSetting(context) {
    context.commit('setYTSetting')
  },

  async showYTBatchSetting(context) {
    context.commit('setYTBatchSetting')
  },

  async showSubtitleStyle(context, styleId?: string) {
    // if (styleId) await context.dispatch('loadSubtileStyle', styleId)
    context.commit('setSubtitleStyle')
  },

  async showAutoPublishSetting(context) {
    context.commit('setAutoPublishSetting')
  },
  showTagPreview(context, setting: any) {
    context.commit('setTagPreview', setting)
  },
}

const modal: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default modal
