






































































import { useGetVoiceDetails, useGetVoiceLanguages } from '@/hooks/transcript'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import get from 'lodash.get'
import { computed, reactive, ref } from '@vue/composition-api'
import { Getter, Action } from 'vuex-class'

@Component({
  setup(props: any) {
    const provider = computed(() => (props.quality === 'PREMIUM' ? 'elevenlabs' : 'aws'))
    const { data: voiceLanguages } = useGetVoiceLanguages(provider)
    const voiceType = ref('standard')
    const voiceGender = ref('FEMALE')
    const voiceLanguage = ref('en-US')
    const voiceDetailsParams = reactive({
      languageCode: voiceLanguage,
      engine: voiceType,
      gender: voiceGender,
    })
    const { data: voiceDetailData, isFetching: isVoiceFetching } = useGetVoiceDetails(provider, voiceDetailsParams)
    return {
      voiceType,
      voiceGender,
      voiceLanguages,
      voiceLanguage,
      voiceDetailData,
      isVoiceFetching,
    }
  },
})
export default class VoiceSelection extends Vue {
  @Prop(String) buttonTitle!: string
  @Prop(Boolean) disabled!: boolean
  @Prop(String) quality!: string

  @Getter networkType!: any

  voiceLanguages!: any
  isVoiceFetching!: boolean

  voiceType!: string
  voiceGender!: string
  voiceLanguage!: string

  voiceGenderList = [
    { title: 'Male', value: 'MALE' },
    { title: 'Female', value: 'FEMALE' },
  ]
  voiceDetailData!: any

  selectedVoice: any = {}

  mounted() {
    this.handleVoiceDetailData()
  }

  @Watch('quality')
  handleChange() {
    this.voiceType = 'standard'
    this.voiceGender = 'FEMALE'
  }
  @Watch('voiceDetailData')
  handleVoiceDetailData() {
    if (this.voiceDetailData.data.length) {
      this.selectedVoice = this.voiceDetailData.data[0]
    }
  }

  get voiceTypeList() {
    return [
      { title: 'Realistic (PRO)', value: 'neural' },
      { title: 'Simple', value: 'standard' },
    ]
  }

  get voicesList() {
    return get(this.voiceDetailData, 'data', [])
  }

  get languages() {
    return get(this.voiceLanguages, 'data', [{ language: 'English(US)', languageCode: 'en-US' }])
  }

  audio!: HTMLAudioElement
  isAudioPlaying = false

  playAudio(voice: any) {
    if (this.audio) {
      !this.audio.paused && this.audio.pause()
      this.audio = new Audio()
      this.isAudioPlaying = false
    }
    this.audio = new Audio()
    const url = voice.sampleAudioUrl

    this.audio = new Audio(url)
    this.audio.play()
    this.audio.addEventListener('ended', () => {
      this.isAudioPlaying = false
      this.audio.removeEventListener('ended', () => {})
    })
    this.isAudioPlaying = true
  }

  pauseAudio() {
    !this.audio.paused && this.audio.pause()
    this.audio = new Audio()
    this.isAudioPlaying = false
  }

  async submitVoiceDetails() {
    // if (['VIDEO_FREE', 'VIDEO_BASIC'].includes(this.networkType) && this.quality === 'PREMIUM') {
    //   this.$store.commit('setCurrentModal', 'plans')
    //   return
    // }
    this.$emit('voice-details', {
      voiceId: this.selectedVoice.voiceId,
      language: this.selectedVoice.languageCode,
      //   voiceType: this.voiceType,
      service: this.quality === 'PREMIUM' ? 'elevenlabs' : 'aws',
    })
  }

  destroyed() {
    this.audio.pause()
  }

  changeType(val: string) {
    this.voiceType = val
    this.pauseAudio()
  }

  selectVoice(voice: any) {
    this.selectedVoice = voice
  }

  @Watch('voiceType')
  onVoiceTypeChanged() {
    this.$emit('change-type', this.voiceType)
  }
}
