














































































































































































































































































import { useGetBillingSummary, useUpgradePlan } from '@/hooks/billing'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import get from 'lodash.get'
import { formatTimeAgo } from '@/utils/time'
import { Crisp } from 'crisp-sdk-web'

@Component({
  setup() {
    const { mutate: upgradePlan, isLoading: isUpgrading } = useUpgradePlan()
    const { data: summaryData, isLoading: isSummaryLoading } = useGetBillingSummary({
      enabled: true,
    })
    return {
      upgradePlan,
      isUpgrading,
      summaryData,
    }
  },
})
export default class Plans extends Vue {
  @Getter networkType!: any

  @Action closeModal!: any

  @Action pushNotification!: any

  selectedPlanMode: 'ANNUALLY' | 'MONTHLY' = 'ANNUALLY' //  MONTHLY

  plansHierarchy = [
    'VIDEO_FREE',
    'VIDEO_BASIC',
    'VIDEO_PRO',
    'ADORI_BASIC_MONTHLY',
    'ADORI_PREMIUM_MONTHLY',
    'ADORI_BUSINESS_MONTHLY',
    'ADORI_BASIC_YEARLY',
    'ADORI_PREMIUM_YEARLY',
    'ADORI_BUSINESS_YEARLY',
  ]

  get locationUrl() {
    return window.location.href
  }

  get plans() {
    const plans = {
      MONTHLY: [
        {
          name: 'Free Trial',
          networkType: 'VIDEO_FREE',
          planActive: this.networkType === 'VIDEO_FREE',
          price: 0,
          discountedPrice: 0,
          discountedAmount: 0,
          annualPrice: 0,
          popular: false,
          features: {
            videoCreation: 1,
            videoExport: 0,
            maxScriptLength: '1 minute',
            resolution: 'HD',
            aiImageGenerator: '20 images',
            publishToYoutube: false,
            customerVoiceCloning: false,
            curationSupport: false,
          },
        },
        {
          name: 'Basic',
          networkType: 'ADORI_BASIC_MONTHLY',
          planActive: this.networkType === 'ADORI_BASIC_MONTHLY',
          price: 15,
          discountedPrice: 12,
          discountedAmount: 36,
          annualPrice: 144,
          popular: false,
          features: {
            videoCreation: 10,
            videoExport: 20,
            maxScriptLength: '3 minutes',
            resolution: 'HD',
            aiImageGenerator: '100 images',
            publishToYoutube: false,
            customerVoiceCloning: false,
            curationSupport: false,
          },
        },
        {
          name: 'Premium',
          networkType: 'ADORI_PREMIUM_MONTHLY',
          planActive: this.networkType === 'ADORI_PREMIUM_MONTHLY',
          price: 45,
          discountedPrice: 36,
          discountedAmount: 108,
          annualPrice: 432,
          popular: true,
          features: {
            videoCreation: 20,
            videoExport: 40,
            maxScriptLength: '5 minutes',
            resolution: 'Full HD',
            aiImageGenerator: '300 images',
            publishToYoutube: true,
            customerVoiceCloning: false,
            curationSupport: false,
          },
        },
        {
          name: 'Business',
          networkType: 'ADORI_BUSINESS_MONTHLY',
          planActive: this.networkType === 'ADORI_BUSINESS_MONTHLY',
          price: 199,
          discountedPrice: 158,
          discountedAmount: 492,
          annualPrice: 1900,
          popular: false,
          features: {
            videoCreation: 50,
            videoExport: 100,
            maxScriptLength: '10 minutes',
            resolution: 'Full HD',
            aiImageGenerator: '1000 images',
            publishToYoutube: true,
            customerVoiceCloning: true,
            curationSupport: '30 minutes per month',
          },
        },
      ],
      ANNUALLY: [
        {
          name: 'Free Trial',
          networkType: 'VIDEO_FREE',
          planActive: this.networkType === 'VIDEO_FREE',
          price: 0,
          popular: false,
          features: {
            videoCreation: 1,
            videoExport: 0,
            maxScriptLength: '1 minute',
            resolution: 'HD',
            aiImageGenerator: '20 images',
            publishToYoutube: false,
            customerVoiceCloning: false,
            curationSupport: false,
          },
        },
        {
          name: 'Basic',
          networkType: 'ADORI_BASIC_YEARLY',
          planActive: this.networkType === 'ADORI_BASIC_YEARLY',
          price: 12,
          discountedPrice: 94,
          discountedAmount: 36,
          annualPrice: 144,
          popular: false,
          features: {
            videoCreation: 120,
            videoExport: 240,
            maxScriptLength: '3 minutes',
            resolution: 'HD',
            aiImageGenerator: '1.2k images',
            publishToYoutube: false,
            customerVoiceCloning: false,
            curationSupport: false,
          },
        },
        {
          name: 'Premium',
          networkType: 'ADORI_PREMIUM_YEARLY',
          planActive: this.networkType == 'ADORI_PREMIUM_YEARLY',
          price: 36,
          discountedPrice: 281,
          discountedAmount: 108,
          annualPrice: 432,
          popular: true,
          features: {
            videoCreation: 240,
            videoExport: 480,
            maxScriptLength: '5 minutes',
            resolution: 'Full HD',
            aiImageGenerator: '3.6k images',
            publishToYoutube: true,
            customerVoiceCloning: false,
            curationSupport: false,
          },
        },
        {
          name: 'Business',
          networkType: 'ADORI_BUSINESS_YEARLY',
          planActive: this.networkType == 'ADORI_BUSINESS_YEARLY',
          price: 158,
          discountedPrice: 1235,
          discountedAmount: 488,
          annualPrice: 1900,
          popular: false,
          features: {
            videoCreation: 600,
            videoExport: 1200,
            maxScriptLength: '10 minutes',
            resolution: 'Full HD',
            aiImageGenerator: '12k images',
            publishToYoutube: true,
            customerVoiceCloning: true,
            curationSupport: '30 minutes per month',
          },
        },
      ],
    }
    return plans
  }

  get legacyPlans() {
    const plans = {
      VIDEO_FREE: {
        name: 'Free Trial',
        networkType: 'VIDEO_FREE',
        planActive: this.networkType === 'VIDEO_FREE',
        price: 0,
        popular: false,
        features: {
          videoCreation: 3,
          videoExport: 0,
          maxScriptLength: '1 minute',
          resolution: 'HD',
          aiImageGenerator: '20 images',
          publishToYoutube: false,
          customerVoiceCloning: false,
          curationSupport: false,
        },
      },
      VIDEO_BASIC: {
        name: 'Legacy Basic Plan ($9.99/month)',
        networkType: 'VIDEO_BASIC',
        planActive: this.networkType === 'VIDEO_BASIC',
        price: 9.99,
        popular: false,
        features: {
          videoCreation: 10,
          videoExport: 10,
          maxScriptLength: '15 minutes',
          resolution: 'HD',
          aiImageGenerator: '100 images',
          publishToYoutube: true,
          customerVoiceCloning: false,
          curationSupport: false,
        },
      },
      VIDEO_PRO: {
        name: 'Legacy Pro Plan  ($29.99/month)',
        networkType: 'VIDEO_PRO',
        planActive: this.networkType === 'VIDEO_PRO',
        price: 29.99,
        popular: false,
        features: {
          videoCreation: 30,
          videoExport: 30,
          maxScriptLength: '30 minutes',
          resolution: 'Full HD',
          aiImageGenerator: '300 images',
          publishToYoutube: true,
          customerVoiceCloning: true,
          curationSupport: false,
        },
      },
    }
    return plans
  }

  get currentPlan() {
    // @ts-ignore
    if (this.legacyPlans[this.networkType]) {
      // @ts-ignore
      return this.legacyPlans[this.networkType]
    }
    for (const mode of ['MONTHLY', 'ANNUALLY']) {
      // @ts-ignore
      const plan = this.plans[mode].find((p) => p.networkType === this.networkType)
      if (plan) {
        return plan
      }
    }

    return null
  }

  upgradePlan!: any
  isUpgrading!: boolean
  summaryData!: any

  get planDetails() {
    return get(this, 'summaryData', null)
  }

  get renewalDate() {
    return formatTimeAgo(this.planDetails?.current_period_end)
  }
  get cancelAt() {
    return this.planDetails?.cancel_at ? formatTimeAgo(this.planDetails?.cancel_at) : false
  }

  getIndex(planNetworkType: string) {
    return this.plansHierarchy.indexOf(planNetworkType)
  }

  btnText(planNetworkType: string) {
    const currentPlan = this.networkType

    if (planNetworkType === currentPlan) {
      return 'CURRENT PLAN'
    }

    const currentIndex = this.plansHierarchy.indexOf(currentPlan)
    const targetIndex = this.plansHierarchy.indexOf(planNetworkType)

    if (targetIndex < currentIndex) {
      return 'Contact Us'
    } else {
      return 'UPGRADE PLAN'
    }
  }

  openChat() {
    console.log('hi')

    //@ts-ignore
    Crisp.message.setMessageText('Hey Adori Support Team I want to downgrade can you help...')
    Crisp.chat.open()
  }

  setPlanMode(mode: 'ANNUALLY' | 'MONTHLY') {
    this.selectedPlanMode = mode
  }

  choosePlan(toType: string) {
    if (toType === this.networkType) return
    if (this.getIndex(toType) < this.getIndex(this.networkType)) {
      this.openChat()
      return
    }
    this.$store.dispatch('showConfirm', {
      title: 'Upgrade',
      description: 'Are you sure you want to upgrade the plan?',
      yesButtonText: 'Yes',
      noButtonText: 'No, stay on current plan',
      onConfirm: async () => {
        try {
          this.upgradePlan({
            toType,
            fromType: this.networkType,
            locationUrl: this.locationUrl,
          })
        } catch (err) {}
        this.closeModal()
      },
    })
  }

  copyToClipboard() {
    // @ts-ignore
    this.$clipboard('ADORI35OFF')
    this.pushNotification({ text: 'Promotional Code Copied to clipboard', type: 'SUCCESS' })
  }
}
