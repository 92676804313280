var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "white" }, [
    _vm.currentPlan
      ? _c("div", { staticClass: "justify-between subscription-details" }, [
          _c("div", [
            _c("h3", [_vm._v("Subscription Details")]),
            _c("div", { staticClass: "current-plan" }, [
              _c("h4", [_vm._v("Current Plan")]),
              _c("span", { staticClass: "black mr3" }, [
                _c("strong", [_vm._v("Plan Name:")]),
                _vm._v(" " + _vm._s(_vm.currentPlan.name)),
              ]),
              _vm.planDetails && _vm.networkType !== "VIDEO_FREE"
                ? _c("span", { staticClass: "black mr3" }, [
                    _c("strong", [_vm._v("Subscription Status:")]),
                    _c(
                      "span",
                      {
                        staticClass: "status-badge ml2 bg-orange",
                        class: {
                          "bg-green": !_vm.cancelAt,
                          "bg-adori-red": !!_vm.cancelAt,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.cancelAt
                              ? "Cancelled " + _vm.cancelAt
                              : "Renewed " + _vm.renewalDate
                          )
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm.networkType === "VIDEO_FREE"
            ? _c("div", [
                _c("div", { staticClass: "banner-container" }, [
                  _c("div", { staticClass: "sale-banner" }, [
                    _c("div", {
                      staticClass: "banner-decoration decoration-1",
                    }),
                    _c("div", {
                      staticClass: "banner-decoration decoration-2",
                    }),
                    _c("div", { staticClass: "discount-badge" }, [
                      _vm._v("35% OFF"),
                    ]),
                    _c("div", { staticClass: "banner-content" }, [
                      _c("h2", [_vm._v("LIMITED TIME OFFER")]),
                      _c("p", [
                        _vm._v(
                          "Sign up for any of our Annual Plans during April 2025, and you'll save an extra 35%!"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "Just use below code at checkout to redeem this special offer."
                        ),
                      ]),
                      _c("div", { staticClass: "promo-container" }, [
                        _c(
                          "div",
                          {
                            staticClass: "promo-code",
                            attrs: { id: "promoCode" },
                          },
                          [_vm._v("ADORI35OFF")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "copy-button",
                            on: {
                              click: function ($event) {
                                return _vm.copyToClipboard()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons icon" }, [
                              _vm._v("content_copy"),
                            ]),
                            _vm._v(
                              "\n                Copy Code\n              "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "copied-message",
                          attrs: { id: "copiedMessage" },
                        },
                        [_vm._v("Code copied!")]
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _c("div", { staticClass: "f5 flex center justify-center mv3" }, [
      _c(
        "div",
        {
          staticClass: "pa2 br3 br--left pointer",
          class:
            _vm.selectedPlanMode == "MONTHLY"
              ? "bg-adori-red"
              : "bg-adori-light-gray",
          on: {
            click: function ($event) {
              return _vm.setPlanMode("MONTHLY")
            },
          },
        },
        [_vm._v("\n      Monthly\n    ")]
      ),
      _c(
        "div",
        {
          staticClass: "pa2 br3 br--right pointer",
          class:
            _vm.selectedPlanMode == "ANNUALLY"
              ? "bg-adori-red"
              : "bg-adori-light-gray",
          on: {
            click: function ($event) {
              return _vm.setPlanMode("ANNUALLY")
            },
          },
        },
        [_vm._v("\n      Annually\n      "), _c("span", [_vm._v("(Save 20%)")])]
      ),
    ]),
    _c("div", { staticClass: "pricing-container br3" }, [
      _c("div", { staticClass: "pricing-table" }, [
        _c(
          "div",
          { staticClass: "pricing-header" },
          [
            _c("div", { staticClass: "feature-label" }),
            _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
              return _c(
                "div",
                {
                  key: tier.name,
                  staticClass: "pricing-column flex-column",
                  class: {
                    "premium-column relative overflow-hidden": tier.popular,
                  },
                },
                [
                  _c("div", { staticClass: "tier-name" }, [
                    _vm._v(
                      "\n            " + _vm._s(tier.name) + "\n            "
                    ),
                    tier.popular
                      ? _c("span", { staticClass: "popular-tag" }, [
                          _vm._v("Popular"),
                        ])
                      : _vm._e(),
                  ]),
                  tier.price == 0
                    ? _c("div", { staticClass: "tier-price f3" }, [
                        _vm._v("$" + _vm._s(tier.price)),
                        _c("span", { staticClass: "f6" }, [_vm._v("/forever")]),
                      ])
                    : _vm.selectedPlanMode === "MONTHLY"
                    ? _c("div", { staticClass: "tier-price f3" }, [
                        _vm._v("\n            $" + _vm._s(tier.price)),
                        _c("span", { staticClass: "f6" }, [_vm._v("/month")]),
                      ])
                    : _vm._e(),
                  tier.price != 0 &&
                  _vm.selectedPlanMode == "ANNUALLY" &&
                  _vm.networkType === "VIDEO_FREE"
                    ? _c("div", { staticClass: "silver mt1" }, [
                        _c("s", [
                          _vm._v(
                            "$" + _vm._s(tier.annualPrice) + " billed annually"
                          ),
                        ]),
                      ])
                    : tier.price != 0 && _vm.selectedPlanMode == "ANNUALLY"
                    ? _c("div", [
                        _vm._v(
                          "\n            $" +
                            _vm._s(tier.annualPrice) +
                            " billed annually\n          "
                        ),
                      ])
                    : _vm._e(),
                  tier.price != 0 &&
                  _vm.selectedPlanMode == "ANNUALLY" &&
                  _vm.networkType === "VIDEO_FREE"
                    ? _c("div", { staticClass: "silver mt1 green" }, [
                        _vm._v(
                          "\n            $" +
                            _vm._s(tier.discountedPrice) +
                            " after 20%+35% OFF\n          "
                        ),
                      ])
                    : _vm._e(),
                  tier.price != 0 &&
                  _vm.selectedPlanMode == "ANNUALLY" &&
                  _vm.networkType !== "VIDEO_FREE"
                    ? _c("div", { staticClass: "silver mt1 green" }, [
                        _vm._v(
                          "\n            Save $" +
                            _vm._s(tier.discountedAmount) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass:
                        "mta w-100 pointer br2 bg-transparent ba bg-adori-red shadow-2 mt3 white pa2 f6 pointer flex justify-center",
                      class: {
                        cmtop:
                          tier.price == 0 && _vm.selectedPlanMode == "ANNUALLY",
                        "bg-adori-subtle-red":
                          tier.planActive ||
                          _vm.getIndex(tier.networkType) <
                            _vm.getIndex(_vm.networkType),
                        "o-40 not-allowed": _vm.isUpgrading,
                      },
                      attrs: { disabled: _vm.isUpgrading },
                      on: {
                        click: function ($event) {
                          return _vm.choosePlan(tier.networkType)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.btnText(tier.networkType)) +
                          "\n            "
                      ),
                      _vm.isUpgrading
                        ? _c("div", { staticClass: "ml2 loader loader-inline" })
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        ),
        _c("div", { staticClass: "features-grid" }, [
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("Video Creation"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-video",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(tier.features.videoCreation) +
                        "\n          "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("Video Downloads"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-export",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    tier.features.videoExport
                      ? _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(tier.features.videoExport) +
                              "\n            "
                          ),
                        ])
                      : _c("span", { staticClass: "cross" }, [_vm._v("✕")]),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("Max. Video Length"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-script",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(tier.features.maxScriptLength) +
                        "\n          "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("Resolution"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-resolution",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(tier.features.resolution) +
                        "\n          "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("AI Image Generator"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-ai",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(tier.features.aiImageGenerator) +
                        "\n          "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("Publish to YouTube"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-youtube",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    tier.features.publishToYoutube
                      ? _c("span", { staticClass: "checkmark" }, [_vm._v("✓")])
                      : _c("span", { staticClass: "cross" }, [_vm._v("✕")]),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("PRO Voices"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-youtube",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    tier.features.publishToYoutube
                      ? _c("span", { staticClass: "checkmark" }, [_vm._v("✓")])
                      : _c("span", { staticClass: "cross" }, [_vm._v("✕")]),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("Voice Cloning"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-voice",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    tier.features.customerVoiceCloning
                      ? _c("span", { staticClass: "checkmark" }, [_vm._v("✓")])
                      : _c("span", { staticClass: "cross" }, [_vm._v("✕")]),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("Curation Support"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-curation",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    typeof tier.features.curationSupport === "string"
                      ? [
                          _vm._v(
                            "\n              " +
                              _vm._s(tier.features.curationSupport) +
                              "\n            "
                          ),
                        ]
                      : _c("span", { staticClass: "cross" }, [_vm._v("✕")]),
                  ],
                  2
                )
              }),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }